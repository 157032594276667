import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import "./style.scss";

import logo from "../../assets/images/logo.png";
import lightbulb from "../../assets/images/lightbulb.png";
import lightbulbOff from "../../assets/images/lightbulb-off.png";

import EmojiInput from "../../components/EmojInput";
import ThemeSwitch from "../../components/ThemeSwitch";
import LightSwitch from "../../components/LightSwitch";
import EmojiBoard from "../../components/EmojiBoard";
import Gameboy from "../../components/Gameboy";
import Social from "../../components/Social";
import Calendar from "../../components/Calendar";
import Record from "../../components/Record";
import HelpCase from "../../components/HelpCase";
import Mood from "../../components/Mood";
import Info from "../../components/Info";
import Shelf from "../../components/Shelf";
import DoodlePad from "../../components/DoodlePad";
import HelpIcon from "../../components/HelpIcon";
import Basketball from "../../components/Basketball";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 300);
  }

  render() {
    var landingClass = classNames(`theme-${this.props.theme}`, "landing", {
      loaded: this.state.loaded,
      dark: !this.props.light,
      light: this.props.light,
      lower: this.props.isGeneratorVisable && this.props.stage === 0,
      stage1: this.props.stage === 1 && this.props.isGeneratorVisable,
      stage2: this.props.stage === 3,
      konami: this.props.konami,
    });

    return (
      <div className={landingClass}>
        <div className="texture-overlay"></div>
        <div className="desk-inner">
          {!this.props.light ? (
            <img src={lightbulbOff} className="lightbulb" alt="" />
          ) : (
            <img src={lightbulb} className="lightbulb" alt="" />
          )}
          <img src={logo} className="landing-logo" alt="Feels FM Logo" />
          <EmojiInput isEmojiBoardVisible={this.props.isEmojiBoardVisible} />
          <div className="start-faded">
            <ThemeSwitch />
            <LightSwitch />
            <Gameboy />
            <Social />
            <Calendar />
            <Info />
            <Mood />
            <Record recordSpeed={2} />
            <HelpCase />
            <Shelf />
            <DoodlePad />
            <HelpIcon />
            <Basketball />
          </div>
          <EmojiBoard visible={this.props.isEmojiBoardVisible} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    theme: state.theme.theme,
    light: state.theme.light,
    isEmojiBoardVisible: state.control.isEmojiBoardVisible,
    isGeneratorVisable: state.control.isGeneratorVisable,
    stage: state.chat.stage,
    konami: state.control.konamiCode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(Index);

export default App;
