import React from "react";
import "./style.scss";

const UserMessage = ({ image, text }) => (
  <div className="message user">
    <div className="message-text">
      <span>{text}</span>
    </div>
    <div className="profile">
      <div className="profile-inner">
        <img src={image} alt="" />
      </div>
    </div>
  </div>
);

export default UserMessage;
