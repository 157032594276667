import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import "./style.scss";

import GeneratorActions from "../../actions/GeneratorActions";

import Chat from "../../components/Chat";
import HelpIcon from "../../components/HelpIcon";

class Generator extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.loadGenerator();
    }, 300);
  }

  render() {
    var generatorClass = classNames(`theme-${this.props.theme}`, "generator", {
      loaded: !this.props.generatorLoading,
      step1: this.props.stage === 1,
      step2: this.props.stage === 2,
      step3: this.props.stage === 3,
    });

    return (
      <div className={generatorClass}>
        <div className="generator-inner">
          <div className="generator-wrap">
            <div className="generator-loading">
              <p>Generating playlist for...</p>
              <img
                src={this.props.selectedEmoji.image}
                alt={this.props.selectedEmoji.emoji}
              />
            </div>
            <Chat />
          </div>
        </div>
        <HelpIcon />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    generatorLoading: state.generator.loading,
    selectedEmoji: state.control.selectedEmoji,
    theme: state.theme.theme,
    stage: state.chat.stage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadGenerator: () => {
      dispatch(GeneratorActions.loadGenerator());
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(Generator);

export default App;
