import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import moodSwitch from "../../assets/images/mood-switch.png";

import "./style.scss";

import Hand1 from "../../assets/images/hand-1.png";
import Hand2 from "../../assets/images/hand-2.png";
import Hand3 from "../../assets/images/hand-3.png";

class Mood extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mood: 2,
      touched: false,
    };
  }

  componentDidMount() {
    setInterval(() => {
      if (this.state.touched) {
        return;
      }
      for (var x = 0, ln = 6; x < ln; x++) {
        setTimeout((y) => {
          let newMood = this.state.mood + 1;
          if (newMood > 3) {
            newMood = 1;
          }
          this.setState({ mood: newMood });
        }, x * 300);
      }
    }, 5000);
  }

  render() {
    var moodClass = classNames(`mood-${this.state.mood}`, "mood", {});
    return (
      <div className={moodClass}>
        <div className="hands">
          <img
            src={Hand1}
            className="hand1"
            onClick={() => {
              this.setState({ mood: 1, touched: true });
            }}
            alt=""
          />
          <img
            src={Hand2}
            className="hand2"
            onClick={() => {
              this.setState({ mood: 2, touched: true });
            }}
            alt=""
          />
          <img
            src={Hand3}
            className="hand3"
            onClick={() => {
              this.setState({ mood: 3, touched: true });
            }}
            alt=""
          />
        </div>
        <div className="runner">
          <div
            className="one"
            onClick={() => {
              this.setState({ mood: 1, touched: true });
            }}
          />
          <div
            className="two"
            onClick={() => {
              this.setState({ mood: 2, touched: true });
            }}
          />
          <div
            className="three"
            onClick={() => {
              this.setState({ mood: 3, touched: true });
            }}
          />
        </div>
        <img
          src={moodSwitch}
          onClick={() => {
            let num = this.state.mood + 1;
            if (num > 3) {
              num = 1;
            }
            this.setState({ mood: num, touched: true });
          }}
          alt=""
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(Mood);

export default App;
