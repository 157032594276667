import { combineReducers } from "redux";
import ThemeReducer from "./reducers/ThemeReducer.js";
import ControlReducer from "./reducers/ControlReducer.js";
import GeneratorReducer from "./reducers/GeneratorReducer.js";
import ChatReducer from "./reducers/ChatReducer.js";
import DesktopReducer from "./reducers/DesktopReducer.js";
import RecordReducer from "./reducers/RecordReducer.js";

const allReducers = combineReducers({
  theme: ThemeReducer,
  control: ControlReducer,
  generator: GeneratorReducer,
  chat: ChatReducer,
  desktop: DesktopReducer,
  record: RecordReducer,
});

export default allReducers;
