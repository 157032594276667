const initialState = {
  theme: "pink",
  themeIndex: 1,
  light: true,
};

const themes = ["yellow", "pink", "blue", "green"];

export default function ThemeReducer(state = initialState, action) {
  switch (action.type) {
    case "CHANGE_THEME":
      let newThemeIndex;
      if (action.payload !== undefined) {
        newThemeIndex = action.payload - 1;
      } else {
        if (state.themeIndex < themes.length - 1) {
          newThemeIndex = state.themeIndex + 1;
        } else {
          newThemeIndex = 0;
        }
      }
      return {
        ...state,
        theme: themes[newThemeIndex],
        themeIndex: newThemeIndex,
      };
    case "SWITCH_LIGHT":
      return {
        ...state,
        light: !state.light,
      };
    default:
      break;
  }

  return state;
}
