const initialState = {
  gameboyIsOpen: false,
  pennyIsOpen: false,
};

export default function DesktopReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_GAMEBOY":
      return {
        ...state,
        gameboyIsOpen: !state.gameboyIsOpen,
      };
    case "TOGGLE_PENNY":
      return {
        ...state,
        pennyIsOpen: !state.pennyIsOpen,
      };
    default:
      break;
  }

  return state;
}
