import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import ThemeActions from "../../actions/ThemeActions";

import "./style.scss";

import clickSound from "../../assets/audio/click.wav";

class LightSwitch extends Component {
  render() {
    var lightSwitchClass = classNames("light-switch", {
      lit: this.props.light,
    });
    function playClick() {
      document.getElementById("light-clickSound").volume = 0.3;
      document.getElementById("light-clickSound").play();
    }
    return (
      <div
        className={lightSwitchClass}
        onClick={() => {
          playClick();
          this.props.switchLight();
        }}
      >
        <audio id="light-clickSound" src={clickSound} />
        <div className="light-switch-button" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    light: state.theme.light,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    switchLight: (theme) => {
      dispatch(ThemeActions.switchLight());
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(LightSwitch);

export default App;
