import React, { Component } from "react";
import { connect } from "react-redux";

import "./style.scss";
import calendar from "../../assets/images/calendar.png";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    return (
      // <a
      //   href="https://www.seemescotland.org/young-people/resources/campaigns/run-your-feels-fm-activity/"
      //   target="_blank"
      // >
      <div className={this.state.open ? "calendar open" : "calendar"}>
        <div className="question">
          <span>
            We’ve designed a FeelsFM activity, using music to help young people
            chat about mental health. The activity pack can be used in schools,
            youth work and with friends and families.{" "}
          </span>
          <div className="user-response-buttons">
            <div
              className="user-response-button"
              onClick={() => {
                this.setState({ open: !this.state.open });
                window.open(
                  "https://www.seemescotland.org/young-people/resources/campaigns/run-your-feels-fm-activity/"
                );
              }}
            >
              <span>Download your pack</span>
            </div>
          </div>
        </div>
        <img
          src={calendar}
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}
          alt=""
        />
      </div>
      // </a>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(Calendar);

export default App;
