import React, { Component } from "react";
import { connect } from "react-redux";

import closeIcon from "../../assets/images/close-icon.png";

class HelpIcon extends Component {
  render() {
    return (
      <div className="help-content">
        <div className="help-content--inner">
          <img src={closeIcon} className="close-modal" alt="Close" />
          <div className="scroller">
            <h2>
              If you’re feeling really down and need someone to talk to, there
              are people ready to listen.
            </h2>
            <div className="row">
              <div className="col1">
                <h3>Samaritans</h3>
                <p>
                  Samaritans provide confidential, non-judgemental emotional
                  support.
                </p>
                <div className="details">
                  <span>
                    <strong>Call:</strong> <a href="tel:116123">116 123</a>
                  </span>
                  <span>
                    <strong>Text:</strong>{" "}
                    <a href="tel:07752909090">07752 90 90 90</a>
                  </span>
                  <span>
                    <strong>Site:</strong>{" "}
                    <a
                      href="https://samaritans.org"
                      target="_blank"
                      rel="noreferrer"
                    >
                      samaritans.org
                    </a>
                  </span>
                </div>

                <h3>Breathing Space</h3>
                <p>
                  A free, confidential phone service for anyone experiencing low
                  mood, depression or anxiety.
                </p>
                <div className="details">
                  <span>
                    <strong>Call:</strong>{" "}
                    <a href="tel:0800838587">0800 83 85 87</a>
                  </span>
                  <span>
                    <strong>Site:</strong>{" "}
                    <a
                      href="https://breathingspacescotland.co.uk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      breathingspacescotland.co.uk
                    </a>
                  </span>
                </div>

                <h3>NHS24</h3>
                <p>Urgent health advice out of hours.</p>
                <div className="details">
                  <span>
                    <strong>Call:</strong> <a href="tel:111">111</a> - Or if you
                    think you need an emergency ambulance, call{" "}
                    <a href="tel:999">999</a> and speak to the operator
                  </span>
                  <span>
                    <strong>Site:</strong>{" "}
                    <a
                      href="https://nhs24.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      nhs24.com
                    </a>
                  </span>
                </div>
              </div>

              <div className="col2">
                <h3>Childline Scotland</h3>
                <p>A counselling service for children and young people.</p>
                <div className="details">
                  <span>
                    <strong>Call:</strong> <a href="tel:08001111">0800 1111</a>
                  </span>
                  <span>
                    <strong>Site:</strong>{" "}
                    <a
                      href="https://childline.org.uk"
                      target="_blank"
                      rel="noreferrer"
                    >
                      childline.org.uk
                    </a>
                  </span>
                </div>

                <h3>Young Scot</h3>
                <p>
                  A free and confidential phone service offering a range of
                  advice, including mental health.
                </p>
                <div className="details">
                  <span>
                    <strong>Call:</strong>{" "}
                    <a href="tel:08088010338">0808 801 0338</a>
                  </span>
                </div>
                <h3>7 Cups of Tea</h3>
                <p>An online emotional health and wellbeing service.</p>
                <div className="details">
                  <span>
                    <strong>Site:</strong>{" "}
                    <a
                      href="https://7cups.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      7cups.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(HelpIcon);

export default App;
