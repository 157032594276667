const Actions = {
  toggleEmojiBoard: () => {
    return (dispatch) => {
      dispatch({
        type: "TOGGLE_EMOJI_BOARD",
      });
    };
  },
  selectEmoji: (emoji) => {
    return (dispatch) => {
      dispatch({
        type: "SELECTED_EMOJI",
        payload: emoji,
      });
    };
  },
  openGenrator: () => {
    return (dispatch) => {
      dispatch({
        type: "OPEN_GENERATOR",
      });
    };
  },
  openPlaylist: () => {
    return (dispatch) => {
      dispatch({
        type: "OPEN_PLAYLIST",
      });
    };
  },
  konami: () => {
    return (dispatch) => {
      dispatch({
        type: "KONAMI_CODE",
      });
    };
  },
  toggleIpadSwitch: () => {
    return (dispatch) => {
      dispatch({
        type: "TOGGLE_IPAD_SWTICH",
      });
    };
  },
};

export default Actions;
