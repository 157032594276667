import React, { Component } from "react";
import { connect } from "react-redux";

import Landing from "./containers/Landing";
import Generator from "./containers/Generator";
import Playlist from "./containers/Playlist";
import SavePlaylist from "./containers/SavePlaylist";

import "sanitize.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 3000);
  }

  render() {
    if (window.location.href.includes("access_token")) {
      return (
        <div className="app">
          <SavePlaylist />
        </div>
      );
    } else {
      return (
        <div className="app">
          <Landing />
          {this.props.isGeneratorVisable && <Generator />}
          {this.props.isPlaylistVisable && <Playlist />}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isGeneratorVisable: state.control.isGeneratorVisable,
    isPlaylistVisable: state.control.isPlaylistVisable,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(Index);

export default App;
