import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import HelpContent from "../HelpContent";

import "./style.scss";

import tvText from "../../assets/images/tv-text.png";

class HelpIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false,
    };
  }
  render() {
    var iconClass = classNames("help-case", {
      open: this.state.showContent,
    });

    return (
      <div
        className={iconClass}
        onClick={(e) => {
          if (
            e.target.classList.contains("help-case") ||
            e.target.classList.contains("help-content")
          ) {
            this.setState({ showContent: !this.state.showContent });
          }
        }}
      >
        <img src={tvText} className="tv-text" alt="" />
        <HelpContent />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(HelpIcon);

export default App;
