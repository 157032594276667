const initialState = {
  stage: 1,
  messages: [
    {
      from: "bot",
      text: [
        "Gotcha. Quick question for you. When young people are struggling to cope, how can adults help?",
        "Okie doke. While we’ve got you, could you tell us how adults can make it difficult for young people struggling to cope with their feelings?",
        "Interesting choice. Quick question - If a young person is struggling to cope with their feelings, who would they worry about telling and why?",
        "Okie doke. While we’ve got you, how can families help young people struggling to cope with their feelings?",
        "Gotcha. At FeelsFM, we want to find out how we can help young people struggling to cope with their feelings. Can you tell us how families can make it more difficult?",
        "Interesting choice. While we’ve got you, if a young person is struggling with their feelings, how can people like doctors and teachers show they are taking them seriously?",
        "Gotcha. Quick question - Do you think the COVID pandemic has made people take young people’s feelings more or less seriously, and why? ",
      ][Math.floor(Math.random() * 7)],
    },
  ],
  answers: [],
  warningLevel: null,
  birthYear: null,
  genre: null,
};

export default function ChatReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_STAGE":
      return {
        ...state,
        stage: action.payload,
      };
    case "SET_GENRE":
      return {
        ...state,
        genre: action.payload,
      };
    case "SUBMIT_MESSAGE":
      return {
        ...state,
        messages: state.messages.concat({
          from: action.user,
          text: action.payload,
        }),
      };
    case "ANSWER_SUBMITED":
      let birthYear = null;
      if (state.answers.length === 1) {
        birthYear = action.payload;
      }
      return {
        ...state,
        answers: state.answers.concat(action.payload),
        birthYear: birthYear,
      };
    default:
      break;
  }

  return state;
}
