import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import "./style.scss";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let hashes = window.location.hash.split("=");
    let acessToken = hashes[1];
    let PlaylistId = hashes[4];
    axios
      .put(
        `https://api.spotify.com/v1/playlists/${PlaylistId}/followers`,
        {
          playlist_id: PlaylistId,
        },
        { headers: { Authorization: "Bearer " + acessToken } }
      )
      .then(function (response) {
        window.close();
      });
  }

  render() {
    return (
      <div className="save-playlist">
        <h1>Playlist Saved</h1>
        <p>You can close this window...</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTracks: (data) => {},
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(Index);

export default App;
