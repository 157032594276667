import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import HelpContent from "../HelpContent";

import "./style.scss";

class HelpIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContent: false,
    };
  }

  render() {
    var iconClass = classNames("help-icon", {
      open: this.state.showContent,
    });

    return (
      <div
        className={iconClass}
        onClick={() => {
          this.setState({ showContent: !this.state.showContent });
        }}
      >
        <HelpContent />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const App = connect(mapStateToProps, mapDispatchToProps)(HelpIcon);

export default App;
