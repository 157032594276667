import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { CSSTransitionGroup } from "react-transition-group";

import "./style.scss";

import ChatProfile from "../../assets/images/chat-profile.png";

import ChatActions from "../../actions/ChatActions";
import ControlActions from "../../actions/ControlActions";

import BotMessage from "./botMessage";
import UserMessage from "./userMessage";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      typing: false,
      hideInput: false,
      sending: false,
      showWarning: false,
      showLinks: false,
      submit1disabled: true,
      submit2disabled: true,
    };
  }

  componentDidMount() {
    // this.refs.response.focus();
  }

  _sumbmitAnswer(skip) {
    const warningWordsHigh = [
      "Self-harm",
      "self-harming",
      "suicide",
      "cutting",
      "Giving up",
      "Ending life",
      "Kill",
      "Not worth living",
      "I’m done",
      "hitting me",
      "being hurt",
      "attacking",
      "attacked",
      "shouting",
      "I can’t cope",
      "Overdose",
      "overdosing",
      "overdosed",
      "Slashing",
      "Burning",
      "Hurt myself",
      "hurting",
      "Hurt myself",
    ];
    const warningWordsLow = [
      "Isolation",
      "Isolated",
      "No friends",
      "Worthless",
      "Hopeless",
      "Pointless",
      "what’s the point",
      "FML",
      "fuck my life",
      "SMH",
      "so much hate",
      "Bullying",
      "bullied",
      "bullies",
      "Homophobia",
      "homophobic",
      "Trauma",
      "traumatic",
      "Divorce",
      "divorced",
      "I wish they would leave me alone",
      "frightened",
      "scared",
      "drugs",
      "drinking",
      "drunk",
      "pills",
    ];

    let answer = this.refs.response.value;
    if (!answer && !skip) return;
    this.props.submitAnswer(answer);

    this.props.submitMessage(answer, "user");
    this.setState({
      hideInput: true,
    });

    if (new RegExp(warningWordsHigh.join("|"), "i").test(answer)) {
      setTimeout(() => {
        this.props.submitMessage(
          `It’s really important that to have someone to speak to if you’re in a crisis, and there are some great organisations and people out there who you can talk to. Would you like to… `,
          "bot"
        );
        this.setState({ showWarning: true });
        var container = document.querySelector(".generator-wrap");
        container.querySelector(".user-response-buttons").scrollIntoView();
      }, 500);

      return;
    }

    if (new RegExp(warningWordsLow.join("|"), "i").test(answer)) {
      setTimeout(() => {
        this.props.submitMessage(
          `It’s not always easy to speak about these things, but if you do want to speak to someone, then there are some great organisations and people who you can talk to. Would you like to…`,
          "bot"
        );
        this.setState({ showWarning: true });
        var container = document.querySelector(".generator-wrap");
        container.querySelector(".user-response-buttons").scrollIntoView();
      }, 500);
      return;
    }

    this.setState({ typing: true });
    let firstMessage = this.props.messages[0].text;
    setTimeout(() => {
      let msg = `Ok, let’s get you that playlist. What year were you born?`;

      // if (firstMessage.includes("How does Music affect how you feel")) {
      //   msg =
      //     "Okay, let’s see if we can pull together a playlist for you then, to personalise your playlist, can you tell me what year you were born?";
      // }
      // if (firstMessage.includes("Sometimes it can be hard to describe")) {
      //   msg =
      //     "I see. Well let’s get that playlist made for you. Can you tell me what year were you born?";
      // }
      // if (firstMessage.includes("listen to music when I don’t want to talk")) {
      //   msg =
      //     "Thanks for sharing. Now, that playlist - can you make sure you get the right mix by telling me when were you born?";
      // }

      // if (answer === "") {
      //   msg =
      //     "Now let’s bring together a playlist that might help you with this feeling. Starting with the year you were born.";
      // }

      this.props.submitMessage(msg, "bot");
      this.props.setStage(2);
      this.setState({ typing: false });
      var container = document.querySelector(".generator-wrap");
      container.querySelector(".user-response-buttons").scrollIntoView();
    }, 1000);
  }

  _sumbmitYear() {
    let answer = this.state.selectedOption;
    if (!answer) {
      answer = {
        value: "",
      };
    }
    this.props.submitAnswer(answer.value);

    this.setState({ typing: true });
    this.props.setStage(3);
    setTimeout(() => {
      this.props.submitMessage(
        `We’re on it. One last thing, what kind of music do you want to hear?`,
        "bot"
      );
      this.setState({ typing: false });
      var container = document.querySelector(".generator-wrap");
      container.querySelector(".user-response-buttons.multi").scrollIntoView();
    }, 1000);
  }

  _setGenre(genre) {
    this.props.setGenre(genre);
    this.setState({ sending: true });
    window.scrollTo(0, 0);
    document.querySelector(".generator .generator-wrap .chat").scrollTo(0, 0);
    document.querySelector(".generator .generator-wrap").scrollTo(0, 0);
    setTimeout(() => {
      this.props.openPlaylist();
    }, 1000);
  }

  render() {
    const options = [
      { value: "0", label: "Earlier" },
      { value: "1995", label: "1995" },
      { value: "1996", label: "1996" },
      { value: "1997", label: "1997" },
      { value: "1998", label: "1998" },
      { value: "1999", label: "1999" },
      { value: "2000", label: "2000" },
      { value: "2001", label: "2001" },
      { value: "2002", label: "2002" },
      { value: "2003", label: "2003" },
      { value: "2004", label: "2004" },
      { value: "2005", label: "2005" },
      { value: "2006", label: "2006" },
      { value: "2007", label: "2007" },
      { value: "2008", label: "2008" },
      { value: "2009", label: "2009" },
    ];

    options.reverse();
    let items = "";
    if (this.props.stage !== 4 && !this.state.sending) {
      items = this.props.messages.map((message, i) => {
        if (message.from === "bot") {
          return <BotMessage text={message.text} image={ChatProfile} key={i} />;
        } else {
          if (message.text !== "") {
            return (
              <UserMessage
                key={i}
                text={message.text}
                image={this.props.selectedEmoji.image}
              />
            );
          }
        }
        return false;
      });
    }

    if (this.props.stage === 2) {
      // This is horrible, but I couldn't find a way to pass a default text prop to the <Select> component
      setTimeout(() => {
        if (document.querySelector(".css-151xaom-placeholder")) {
          document.querySelector(".css-151xaom-placeholder").innerText =
            "Select a year";
        }
      }, 1);
    }

    return (
      <div className="chat" ref="chatwindow">
        <CSSTransitionGroup
          transitionName="message"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={3000}
        >
          {items}
        </CSSTransitionGroup>

        {this.state.typing && (
          <div className="typing-indicator">
            <span />
            <span />
            <span />
          </div>
        )}

        {this.props.stage === 1 && !this.state.hideInput && (
          <div className="user-response-container">
            <div className="user-response">
              <textarea
                ref="response"
                placeholder="Type something..."
                id="response"
                rows="3"
                onChange={(e) => {
                  if (e.target.value !== "") {
                    this.setState({
                      submit1disabled: false,
                    });
                  } else {
                    this.setState({
                      submit1disabled: true,
                    });
                  }
                }}
              />
            </div>
            <div className="user-response-buttons">
              <div
                className="user-response-button"
                onClick={this._sumbmitAnswer.bind(this, true)}
              >
                <span>Skip</span>
              </div>
              <div
                className={`user-response-button primary ${
                  this.state.submit1disabled ? "disabled" : ""
                }`}
                onClick={this._sumbmitAnswer.bind(this, false)}
              >
                <span>Submit</span>
              </div>
            </div>
          </div>
        )}

        {this.props.stage === 1 && this.state.showWarning && (
          <div className="user-response-container">
            <div className="user-response-buttons">
              <div
                className="user-response-button"
                style={{ width: 204 }}
                onClick={() => {
                  document.querySelector(".help-icon").click();
                }}
              >
                <span>Talk to Someone</span>
              </div>
              <div
                className="user-response-button"
                style={{ width: 204 }}
                onClick={() => {
                  this.props.submitMessage(
                    "Ok, now let’s bring together a playlist that might help you with this feeling. Starting with the year you were born.",
                    "bot"
                  );
                  this.props.setStage(2);
                  this.setState({ typing: false });
                  setTimeout(() => {
                    var container = document.querySelector(".generator-wrap");
                    container
                      .querySelector(".user-response-buttons")
                      .scrollIntoView();
                  }, 1);
                }}
              >
                <span>Carry on to your Playlist</span>
              </div>
            </div>
          </div>
        )}

        {this.props.stage === 2 && !this.state.sending && (
          <div className="user-response-container">
            <div className="user-response">
              <Select
                value={this.state.selectedOption}
                className="date-picker"
                onChange={(selectedOption) => {
                  this.setState({ selectedOption });
                }}
                options={options}
                isSearchable={false}
              />
            </div>
            <div className="user-response-buttons">
              <div
                className="user-response-button"
                onClick={this._sumbmitYear.bind(this, 3)}
              >
                <span>Skip</span>
              </div>
              <div
                className={`user-response-button primary ${
                  this.state.selectedOption ? "" : "disabled"
                }`}
                onClick={this._sumbmitYear.bind(this, 3)}
              >
                <span>Submit</span>
              </div>
            </div>
          </div>
        )}

        {this.props.stage === 3 && !this.state.sending && !this.state.typing && (
          <div className="user-response-container">
            <div className="user-response-buttons multi">
              <div
                className="user-response-button"
                onClick={this._setGenre.bind(this, `Fun`)}
              >
                <span>Fun</span>
              </div>
              <div
                className="user-response-button"
                onClick={this._setGenre.bind(this, `Quiet`)}
              >
                <span>Quiet</span>
              </div>
              <div
                className="user-response-button"
                onClick={this._setGenre.bind(this, `Loud`)}
              >
                <span>Loud</span>
              </div>
              <div
                className="user-response-button"
                onClick={this._setGenre.bind(this, `surprise`)}
              >
                <span>Surprise Me</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    stage: state.chat.stage,
    messages: state.chat.messages,
    selectedEmoji: state.control.selectedEmoji,
    warningLevel: state.chat.warningLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStage: (stage) => {
      dispatch(ChatActions.setStage(stage));
    },
    submitMessage: (message, user) => {
      dispatch(ChatActions.submitMessage(message, user));
    },
    submitAnswer: (answer) => {
      dispatch(ChatActions.submitAnswer(answer));
    },
    openPlaylist: (answer) => {
      dispatch(ControlActions.openPlaylist());
    },
    setGenre: (genre) => {
      dispatch(ChatActions.setGenre(genre));
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(Chat);

export default App;
