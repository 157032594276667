import React, { Component } from "react";

import "./style.scss";

import shelf from "../../assets/images/shelf.png";

class Shelf extends Component {
  render() {
    return <img className="shelf" src={shelf} alt="" />;
  }
}

export default Shelf;
