import React, { Component } from "react";

import "./style.scss";
import yoyo from "../../assets/images/yoyo.png";
import yoyoSpinLines from "../../assets/images/yoyo-spinlines.png";

class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }
  render() {
    return (
      <>
        <img
          src={yoyo}
          onClick={() => {
            this.setState({ open: !this.state.open });
          }}
          className="info-trigger"
          alt="Info"
        />
        <img src={yoyoSpinLines} className="spin-lines" alt="" />
        <div
          className={this.state.open ? "info open" : "info"}
          onClick={(e) => {
            if (e.target.classList.contains("info")) {
              this.setState({ open: !this.state.open });
            }
          }}
        >
          <div className="info-inner">
            <div className="info-inner-content">
              <h2>What is Feels FM?</h2>
              <p>
                FeelsFM is the world’s first emoji-powered jukebox for mental
                health. FeelsFM is an online platform to help young people to
                express their feelings, use music as a positive coping strategy,
                and find new ways to talk about mental health stigma and
                discrimination.
              </p>
              <br />
              <p>
                Since we launched FeelsFM the jukebox, and the FeelsFM activity
                (which you can find in See Me’s What’s on your Mind? pack{" "}
                <a href="https://www.seemescotland.org/young-people/whats-on-your-mind/">
                  here
                </a>
                ) have been used loads in schools, youth work and with family
                and friends across Scotland, to help young people talk about
                mental health.
              </p>
              <h2>How does Feels FM work?</h2>
              <ul>
                <li>Pick an emoji that represents how you feel</li>
                <li>FeelsFM will generate a playlist based on that feeling</li>
                <li>
                  Whilst it pulls together your mix, it provides an opportunity
                  for you to share your views on mental health.
                </li>
              </ul>
              <p>
                We’re not just asking questions for the sake of it either,
                everything we learn from FeelsFM helps us when working with the
                Scottish Government to make mental health better for young
                people in Scotland.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Info;
