const Actions = {
  toggleRecordIsOn: () => {
    return (dispatch) => {
      dispatch({
        type: "TOGGLE_RECORD_ON",
      });
    };
  },
  switchRecordSpeed: (speed) => {
    return (dispatch) => {
      dispatch({
        type: "SWITCH_RECORD_SPEED",
        payload: speed,
      });
    };
  },
};

export default Actions;
