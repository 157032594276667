const Actions = {
  toggleGameboyOpen: () => {
    return (dispatch) => {
      dispatch({
        type: "TOGGLE_GAMEBOY",
      });
    };
  },
  togglePennyOpen: () => {
    return (dispatch) => {
      dispatch({
        type: "TOGGLE_PENNY",
      });
    };
  },
};

export default Actions;
