import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import axios from "axios";

import "./style.scss";

import GeneratorActions from "../../actions/GeneratorActions";
import HelpIcon from "../../components/HelpIcon";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      playing: null,
      saved: false,
    };
  }

  componentDidMount() {
    this.props.getTracks(
      {
        emoji: this.props.selectedEmoji.emoji,
        genre: this.props.genre,
        birth: this.props.birth,
      },
      false
    );

    if (!window.location.href.includes("localhost")) {
      axios.post(
        "https://3g4shohi3h.execute-api.us-east-1.amazonaws.com/prod/playlist",
        {
          emoji: this.props.selectedEmoji.emoji,
          question: this.props.question.text,
          answer: this.props.answer,
          birth: this.props.birth,
        }
      );
    }

    setTimeout(() => {
      this.setState({ loaded: true });
    }, 300);
  }

  render() {
    var playlistClass = classNames(`theme-${this.props.theme}`, `playlist`, {
      loaded: this.state.loaded,
    });

    return (
      <div className={playlistClass}>
        <HelpIcon />
        {this.props.playlist && (
          <div className="desktop-wrapper">
            <div className="desktop-section">
              <div className="track-list">
                {this.props.playlist.tracks.map((track, i) => {
                  if (track === null) return false;
                  return (
                    <div className="track" key={i}>
                      <div className="meta">
                        <span className="title">{track.name}</span>
                        <span className="artist">{track.artist}</span>
                      </div>
                      <div
                        className="play-btn"
                        onClick={() => {
                          if (this.state.playing === i) {
                            this.setState({ playing: null });
                            this.refs.audio.pause();
                          } else {
                            this.refs.audio.src = track.preview_url;
                            this.refs.audio.play();
                            this.setState({ playing: i });
                          }
                        }}
                      >
                        {this.state.playing === i ? (
                          <i className="fas fa-pause" />
                        ) : (
                          <i className="fas fa-play" />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="btn-container">
                <div
                  className="btn again"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <span>Start Again</span>
                </div>
                <div
                  className="btn shuffle"
                  onClick={() => {
                    this.props.getTracks(
                      {
                        emoji: this.props.selectedEmoji.emoji,
                        genre: "",
                        birth: "",
                      },
                      true
                    );
                  }}
                >
                  <span>Shuffle Tracks</span>
                </div>
              </div>
            </div>

            <div className="desktop-section">
              <p>Here is your playlist for...</p>
              <img
                className="emoji desktop-emoji"
                src={this.props.selectedEmoji.image}
                alt={this.props.selectedEmoji.emoji}
              />

              {!this.state.saved ? (
                <div
                  className="btn spotify"
                  onClick={() => {
                    if (this.state.saved) {
                      return;
                    }
                    let url = `https://accounts.spotify.com/authorize?client_id=1778fedce1c7473e8a03c4c12e3aef63&response_type=token&redirect_uri=http://localhost:3000&scope=playlist-modify-public&state=${this.props.playlist.id}`;
                    if (!window.location.href.includes("localhost")) {
                      url = `https://accounts.spotify.com/authorize?client_id=1778fedce1c7473e8a03c4c12e3aef63&response_type=token&redirect_uri=http://feelsfm.s3-website.eu-west-2.amazonaws.com/&scope=playlist-modify-public&state=${this.props.playlist.id}`;
                    }
                    this.setState({ saved: true });
                    window.open(url);
                  }}
                >
                  <span>
                    SAVE TO SPOTIFY
                    <i className="fab fa-spotify" />
                  </span>
                </div>
              ) : (
                <div
                  className="btn spotify"
                  onClick={() => {
                    let url = `https://open.spotify.com/user/zoofxv77dg72iuey6kw5q9yu8/playlist/${this.props.playlist.id}`;

                    window.open(url);
                  }}
                >
                  <span>
                    OPEN PLAYLIST <i className="fab fa-spotify" />
                  </span>
                </div>
              )}

              <div
                className="btn youtube"
                onClick={() => {
                  window.open(this.props.ytPlaylist.url);
                }}
              >
                <span>
                  VIEW ON YOUTUBE
                  <i className="fab fa-youtube" />
                </span>
              </div>

              <div className="social-share">
                <h4>Share your Feels FM playlist</h4>
                <div className="buttons">
                  <a
                    className="twitter"
                    target="_blank"
                    rel="noreferrer"
                    href={`http://twitter.com/share?text=I’m now listening to ${this.props.selectedEmoji.emoji} on FeelsFm.co.uk&url=https://open.spotify.com/user/zoofxv77dg72iuey6kw5q9yu8/playlist/${this.props.playlist.id}&hashtags=feelsfm`}
                  >
                    <i className="fab fa-twitter" aria-hidden="true"></i>
                  </a>
                  <a
                    className="facebook"
                    target="_blank"
                    rel="noreferrer"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://open.spotify.com/user/zoofxv77dg72iuey6kw5q9yu8/playlist/${this.props.playlist.id}`}
                  >
                    <i className="fab fa-facebook-f" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        <audio src="" ref="audio" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedEmoji: state.control.selectedEmoji,
    selectedYear: state.chat.selectedYear,
    playlist: state.generator.playlist,
    ytPlaylist: state.generator.ytPlaylist,
    answer: state.chat.answers[0],
    question: state.chat.messages[0],
    birth: state.chat.birthYear,
    genre: state.chat.genre,
    theme: state.theme.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTracks: (data, shuffle) => {
      dispatch(GeneratorActions.getTracks(data, shuffle));
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(Index);

export default App;
