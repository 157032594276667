const Actions = {
  setStage: (stage) => {
    return (dispatch) => {
      dispatch({
        type: "SET_STAGE",
        payload: stage,
      });
    };
  },
  submitMessage: (message, user) => {
    return (dispatch) => {
      dispatch({
        type: "SUBMIT_MESSAGE",
        payload: message,
        user: user,
      });
    };
  },
  submitAnswer: (answer) => {
    return (dispatch) => {
      dispatch({
        type: "ANSWER_SUBMITED",
        payload: answer,
      });
    };
  },

  setGenre: (genre) => {
    return (dispatch) => {
      dispatch({
        type: "SET_GENRE",
        payload: genre,
      });
    };
  },
};

export default Actions;
