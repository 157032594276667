import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import ControlActions from "../../actions/ControlActions";

import "./style.scss";

class EmojiInput extends Component {
  render() {
    var emojiInputClass = classNames("emoji-input", {
      // loaded: this.state.loaded
    });

    return (
      <div className={emojiInputClass}>
        <h1>THE EMOJI JUKEBOX</h1>
        <button
          className="start-button"
          onClick={() => {
            if (this.props.light) {
              this.props.popKeyboard();
            }
          }}
        >
          Create your playlist
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    light: state.theme.light,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popKeyboard: (data) => {
      dispatch(ControlActions.toggleEmojiBoard());
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(EmojiInput);

export default App;
