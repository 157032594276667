import React, { Component } from "react";

import "./style.scss";

import SeemeIcon from "../../assets/images/seeme-icon.png";
import FacebookIcon from "../../assets/images/facebook-icon.png";
import TwitterIcon from "../../assets/images/twitter-icon.png";
import InstagramIcon from "../../assets/images/instagram-icon.png";
import YoutubeIcon from "../../assets/images/youtube-icon.png";
import MailIcon from "../../assets/images/email-icon.png";

class Social extends Component {
  render() {
    return (
      <div className="social">
        <label>Powered by See Me</label>
        <div className="social-inner">
          <a
            className="seeme"
            href="https://www.seemescotland.org/young-people/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={SeemeIcon} alt="See Me" />
          </a>
          <a
            className="instagram"
            href="https://www.instagram.com/seemescotland/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={InstagramIcon} alt="Instagram" />
          </a>
          <a
            className="youtube"
            href="https://www.youtube.com/user/seemescotland"
            target="_blank"
            rel="noreferrer"
          >
            <img src={YoutubeIcon} alt="YouTube" />
          </a>
          <a
            className="twitter"
            href="https://twitter.com/seemescotland"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TwitterIcon} alt="Twitter" />
          </a>
          <a
            className="facebook"
            href="https://www.facebook.com/seemescotland"
            target="_blank"
            rel="noreferrer"
          >
            <img src={FacebookIcon} alt="Facebook" />
          </a>
          <a
            className="email"
            href="mailto:info@seemescotland.org"
            target="_blank"
            rel="noreferrer"
          >
            <img src={MailIcon} alt="Email" />
          </a>
        </div>
      </div>
    );
  }
}

export default Social;
