const Actions = {
  changeTheme: (val) => {
    return (dispatch) => {
      dispatch({
        type: "CHANGE_THEME",
        payload: val,
      });
    };
  },
  switchLight: () => {
    return (dispatch) => {
      dispatch({
        type: "SWITCH_LIGHT",
      });
    };
  },
};

export default Actions;
