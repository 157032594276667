import React, { Component } from "react";
import { connect } from "react-redux";

import ThemeActions from "../../actions/ThemeActions";
import ControlActions from "../../actions/ControlActions";

import "./style.scss";

class themeSwitch extends Component {
  constructor(props) {
    super(props);
    this.setKonami = this.setKonami.bind(this);
  }
  componentDidMount() {
    var allowedKeys = {
      37: "left",
      38: "up",
      39: "right",
      40: "down",
      65: "a",
      66: "b",
    };

    // the 'official' Konami Code sequence
    var konamiCode = [
      "up",
      "up",
      "down",
      "down",
      "left",
      "right",
      "left",
      "right",
      "b",
      "a",
    ];

    // a variable to remember the 'position' the user has reached so far.
    var konamiCodePosition = 0;

    // add keydown event listener
    document.addEventListener("keydown", (e) => {
      // get the value of the key code from the key map
      var key = allowedKeys[e.keyCode];
      // get the value of the required key from the konami code
      var requiredKey = konamiCode[konamiCodePosition];

      // compare the key with the required key
      if (key === requiredKey) {
        // move to the next key in the konami code sequence
        konamiCodePosition++;

        // if the last key is reached, activate cheats
        if (konamiCodePosition === konamiCode.length) {
          this.setKonami();
          this.props.konami();

          konamiCodePosition = 0;
        }
      } else {
        konamiCodePosition = 0;
      }
    });
  }

  setKonami() {
    setInterval(() => {
      this.props.changeTheme();
    }, 200);
  }

  render() {
    return (
      <div
        className="theme-swtich"
        onClick={() => {
          this.props.changeTheme();
        }}
      >
        <div
          className="theme-select-0"
          onClick={() => {
            this.props.changeTheme(0);
          }}
        ></div>
        <div
          className="theme-select-1"
          onClick={() => {
            this.props.changeTheme(1);
          }}
        ></div>
        <div
          className="theme-select-2"
          onClick={() => {
            this.props.changeTheme(2);
          }}
        ></div>
        <div
          className="theme-select-3"
          onClick={() => {
            this.props.changeTheme(3);
          }}
        ></div>
        <div className="theme-pointer" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTheme: (theme) => {
      dispatch(ThemeActions.changeTheme(theme));
    },
    konami: (theme) => {
      dispatch(ControlActions.konami());
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(themeSwitch);

export default App;
