import React, { Component } from "react";
import CanvasDraw from "react-canvas-draw";

import "./style.scss";

import doodlePad from "../../assets/images/doodlepad.png";
import knob from "../../assets/images/doodlepad-knob.png";

class DoodlePad extends Component {
  render() {
    return (
      <div className="doodlepad-container">
        <CanvasDraw
          hideGrid={true}
          brushRadius={2}
          brushColor="#FDA5D9"
          canvasWidth={278}
          canvasHeight={179}
          ref="doodlepad"
        />
        <img
          src={knob}
          alt=""
          className="knob-left"
          onClick={() => this.refs.doodlepad.clear()}
        />
        <img
          src={knob}
          alt=""
          className="knob-right"
          onClick={() => this.refs.doodlepad.clear()}
        />
        <img src={doodlePad} alt="" />
      </div>
    );
  }
}

export default DoodlePad;
