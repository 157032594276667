import Playlists from "../playlists.json";
import YTPlaylists from "../ytPlaylists.json";

const Actions = {
  loadGenerator: () => {
    return (dispatch) => {
      dispatch({
        type: "LOAD_GENERATOR",
      });
    };
  },
  getTracks: (data, shuffle) => {
    let era = "26+";

    switch (true) {
      case parseInt(data.birth) > 2008:
        era = "12-16";
        break;
      case parseInt(data.birth) > 2003:
        era = "17-21";
        break;
      case parseInt(data.birth) > 1997:
        era = "21-26";
        break;
      case parseInt(data.birth) > 1994:
        era = "26+";
        break;
      case "Earlier":
        era = "26+";
        break;
      default:
        break;
    }

    if (shuffle) {
      era = ["26+", "12-16", "17-21", "21-26"][Math.floor(Math.random() * 4)];
    }

    if (data.genre === "surprise" || shuffle) {
      data.genre = ["Fun", "Quiet", "Loud"][Math.floor(Math.random() * 3)];
    }

    let playlist = Playlists.find(
      (playlist) =>
        playlist.name.includes(data.emoji) &&
        playlist.name.includes(data.genre) &&
        playlist.name.includes(era)
    );

    let ytPlaylist = YTPlaylists.find(
      (yTPlaylist) =>
        yTPlaylist.name.includes(data.emoji) &&
        yTPlaylist.name.includes(data.genre) &&
        yTPlaylist.name.includes(era)
    );

    // function shuffle(a) {
    //   for (let i = a.length - 1; i > 0; i--) {
    //     const j = Math.floor(Math.random() * (i + 1));
    //     [a[i], a[j]] = [a[j], a[i]];
    //   }
    //   return a;
    // }

    // playlist.tracks.items = shuffle(playlist.tracks.items);

    return (dispatch) => {
      dispatch({
        type: "LOADED_PLAYLIST",
        payload: {
          playlist: playlist,
          ytPlaylist: ytPlaylist,
        },
      });
    };
  },
};

export default Actions;
