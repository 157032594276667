import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import "./style.scss";

import one from "../../assets/audio/1.mp3";
import two from "../../assets/audio/2.mp3";
import three from "../../assets/audio/3.mp3";

import StartButton from "../../assets/images/cassette-start.png";
import StopButton from "../../assets/images/cassette-stop.png";
import SpeedButton from "../../assets/images/cassette-speedbutton.png";
import reelTop from "../../assets/images/cassette-reel-top.png";
import reelBottom from "../../assets/images/cassette-reel-bottom.png";
import soundLines from "../../assets/images/cassette-soundlines.png";

import RecordActions from "../../actions/RecordActions";

import clickSound from "../../assets/audio/click.wav";

class Record extends Component {
  render() {
    var recordSpeedClass = classNames("record", {
      "speed-one": this.props.recordSpeed === 1,
      "speed-two": this.props.recordSpeed === 2,
      "speed-three": this.props.recordSpeed === 3,
      playing: this.props.recordIsOn,
    });

    function playClick() {
      document.getElementById("clickSound").volume = 0.3;
      document.getElementById("clickSound").play();
    }

    return (
      <div className={recordSpeedClass}>
        <audio id="clickSound" src={clickSound} />
        <img
          src={reelTop}
          alt=""
          className={`reel-top ${this.props.recordIsOn ? "playing" : ""}`}
        />
        <img
          src={reelBottom}
          alt=""
          className={`reel-bottom ${this.props.recordIsOn ? "playing" : ""}`}
        />
        <img
          src={soundLines}
          alt=""
          className={`sound-lines ${this.props.recordIsOn ? "playing" : ""}`}
        />
        <div
          className="speed-switch"
          onClick={() => {
            playClick();
            this.props.switchRecordSpeed();
            let audioName;
            let speed = this.props.recordSpeed + 1;
            if (speed > 3) {
              speed = 1;
            }
            switch (speed) {
              case 1:
                audioName = three;
                break;
              case 2:
                audioName = two;
                break;
              case 3:
                audioName = one;
                break;
              default:
                break;
            }

            this.refs.recordAudio.src = audioName;
            this.refs.recordAudio.play();
            if (!this.props.recordIsOn) {
              this.props.toggleRecordIsOn();
            }
          }}
        >
          <img
            className={`speed-button slow ${
              this.props.recordSpeed === 1 ? "pressed" : ""
            }`}
            src={SpeedButton}
            alt="Change speed"
          />
          <img
            className={`speed-button fast ${
              this.props.recordSpeed === 3 ? "pressed" : ""
            }`}
            src={SpeedButton}
            alt="Change speed"
          />
        </div>

        <div
          className="on-off"
          onClick={() => {
            playClick();
            this.props.toggleRecordIsOn();
            if (this.props.recordIsOn) {
              this.refs.recordAudio.pause();
            } else {
              this.refs.recordAudio.play();
            }
          }}
        >
          <img
            src={StartButton}
            alt="Start"
            className={`startbutton ${this.props.recordIsOn ? "pressed" : ""}`}
          />
          <img
            src={StopButton}
            alt="Stop"
            className={`stopbutton ${this.props.recordIsOn ? "" : "pressed"}`}
          />
        </div>
        <audio src={two} ref="recordAudio" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recordIsOn: state.record.isOn,
    recordSpeed: state.record.speed,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRecordIsOn: () => {
      dispatch(RecordActions.toggleRecordIsOn());
    },
    switchRecordSpeed: (speed) => {
      dispatch(RecordActions.switchRecordSpeed(speed));
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(Record);

export default App;
