const initialState = {
  isEmojiBoardVisible: false,
  // selectedEmoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
  selectedEmoji: null,
  isGeneratorVisable: false,
  isPlaylistVisable: false,
  konamiCode: false,
  ipadSwitch: true,
};

export default function ControlReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_EMOJI_BOARD":
      return {
        ...state,
        isEmojiBoardVisible: !state.isEmojiBoardVisible,
      };
    case "SELECTED_EMOJI":
      return {
        ...state,
        selectedEmoji: action.payload,
      };
    case "OPEN_GENERATOR":
      return {
        ...state,
        isGeneratorVisable: true,
      };
    case "OPEN_PLAYLIST":
      return {
        ...state,
        isPlaylistVisable: true,
        isGeneratorVisable: false,
      };
    case "KONAMI_CODE":
      return {
        ...state,
        konamiCode: true,
      };
    case "TOGGLE_IPAD_SWTICH":
      return {
        ...state,
        ipadSwitch: !state.ipadSwitch,
      };
    default:
      break;
  }

  return state;
}
