const initialState = {
  loading: true,
  playlist: null,
  ytPlaylist: null,
};

export default function GeneratorReducer(state = initialState, action) {
  switch (action.type) {
    case "LOAD_GENERATOR":
      return {
        ...state,
        loading: false,
      };
    case "LOADED_PLAYLIST":
      return {
        ...state,
        playlist: action.payload.playlist,
        ytPlaylist: action.payload.ytPlaylist,
      };
    default:
      break;
  }

  return state;
}
