import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";

import ControllActions from "../../actions/ControlActions";

import BotProfilePic from "../../assets/images/chat-profile.png";
import BotMessage from "../Chat/botMessage";

import callMeHand from "../../assets/emojis/2021/call-me-hand.png";
import cheeseWedge from "../../assets/emojis/2021/cheese-wedge.png";
import clownFace from "../../assets/emojis/2021/clown-face.png";
import eyes from "../../assets/emojis/2021/eyes.png";
import faceWithLookOfTriumph from "../../assets/emojis/2021/face-with-look-of-triumph.png";
import faceWithRollingEyes from "../../assets/emojis/2021/face-with-rolling-eyes.png";
import fire from "../../assets/emojis/2021/fire.png";
import foldedHands from "../../assets/emojis/2021/folded-hands.png";
import grinningFace from "../../assets/emojis/2021/grinning-face.png";
import loudlyCryingFace from "../../assets/emojis/2021/loudly-crying-face.png";
import newMoonWithFace from "../../assets/emojis/2021/new-moon-with-face.png";
import palmTree from "../../assets/emojis/2021/palm-tree.png";
import partyPopper from "../../assets/emojis/2021/party-popper.png";
import personInLotusPosition from "../../assets/emojis/2021/person-in-lotus-position.png";
import pleadingFace from "../../assets/emojis/2021/pleading-face.png";
import rainbow from "../../assets/emojis/2021/rainbow.png";
import rollingOnTheFloorLaughing from "../../assets/emojis/2021/rolling-on-the-floor-laughing.png";
import scotland from "../../assets/emojis/2021/scotland.png";
import shockedFaceWithExplodingHead from "../../assets/emojis/2021/shocked-face-with-exploding-head.png";
import sleepingFace from "../../assets/emojis/2021/sleeping-face.png";
import sleeping from "../../assets/emojis/2021/sleeping.png";
import smilingFaceWithHearts from "../../assets/emojis/2021/smiling-face-with-hearts.png";
import smilingFace from "../../assets/emojis/2021/smiling-face.png";
import thinkingFace from "../../assets/emojis/2021/thinking-face.png";
import twoHearts from "../../assets/emojis/2021/two-hearts.png";
import umbrellaWithRainDrops from "../../assets/emojis/2021/umbrella-with-rain-drops.png";
import upsideDownFace from "../../assets/emojis/2021/upside-down-face.png";
import wasteBasket from "../../assets/emojis/2021/wastebasket.png";
import weightLifter from "../../assets/emojis/2021/weight-lifter.png";
import zanyFace from "../../assets/emojis/2021/zany-face.png";

import "./style.scss";

class EmojiBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emojis: [
        {
          image: upsideDownFace,
          emoji: "🙃",
        },
        {
          image: rollingOnTheFloorLaughing,
          emoji: "🤣",
        },
        {
          image: smilingFace,
          emoji: "😊",
        },
        {
          image: loudlyCryingFace,
          emoji: "😭",
        },
        {
          image: sleepingFace,
          emoji: "😴",
        },
        {
          image: smilingFaceWithHearts,
          emoji: "🥰",
        },
        {
          image: pleadingFace,
          emoji: "🥺",
        },
        {
          image: faceWithLookOfTriumph,
          emoji: "😤",
        },
        {
          image: thinkingFace,
          emoji: "🤔",
        },
        {
          image: shockedFaceWithExplodingHead,
          emoji: "🤯",
        },
        {
          image: zanyFace,
          emoji: "🤪",
        },
        {
          image: grinningFace,
          emoji: "😀",
        },
        {
          image: clownFace,
          emoji: "🤡",
        },
        {
          image: eyes,
          emoji: "👀",
        },
        {
          image: callMeHand,
          emoji: "🤙",
        },
        {
          image: palmTree,
          emoji: "🌴",
        },
        {
          image: newMoonWithFace,
          emoji: "🌚",
        },
        {
          image: faceWithRollingEyes,
          emoji: "🙄",
        },
        {
          image: foldedHands,
          emoji: "🙏",
        },
        {
          image: partyPopper,
          emoji: "🎉",
        },
        {
          image: twoHearts,
          emoji: "💕",
        },
        {
          image: fire,
          emoji: "🔥",
        },
        {
          image: wasteBasket,
          emoji: "🗑",
        },
        {
          image: cheeseWedge,
          emoji: "🧀",
        },
        {
          image: rainbow,
          emoji: "🌈",
        },
        {
          image: weightLifter,
          emoji: "🏋️",
        },
        {
          image: personInLotusPosition,
          emoji: "🧘",
        },
        {
          image: sleeping,
          emoji: "🛌",
        },
        {
          image: umbrellaWithRainDrops,
          emoji: "☔️",
        },
        {
          image: scotland,
          emoji: "🏴󠁧󠁢󠁳󠁣󠁴󠁿",
        },
      ],
    };
  }

  _randomEmoji() {
    for (var x = 0, ln = 15; x < ln; x++) {
      setTimeout((y) => {
        this.props.selectEmoji(
          this.state.emojis[
            Math.floor(Math.random() * this.state.emojis.length)
          ]
        );
      }, x * 75);
    }
  }

  render() {
    var boardClass = classNames("emoji-board", {
      in: this.props.visible,
    });

    return (
      <div
        className={boardClass}
        onClick={(e) => {
          if (e.target.classList.contains("emoji-board")) {
            this.props.hideKeyboard();
          }
        }}
      >
        <div className="chat">
          <BotMessage
            text="Hey! Welcome to FeelsFM, the emoji powered jukebox. Which emoji best sums up how you’re feeling?"
            image={BotProfilePic}
          />
        </div>
        <div className="emoji-board-inner">
          <div className="emoji-board-top">
            <div className="emoji-btn-cont">
              <div
                className="emoji-btn red"
                onClick={this._randomEmoji.bind(this)}
              >
                <span>Random Emoji</span>
              </div>
            </div>
            <div className="emoji">
              {this.props.selectedEmoji && (
                <img
                  src={this.props.selectedEmoji.image}
                  alt={this.props.selectEmoji.emoji}
                />
              )}
            </div>
            <div className="emoji-btn-cont">
              <div
                className={
                  this.props.selectedEmoji
                    ? "emoji-btn blue"
                    : "emoji-btn blue disabled"
                }
                onClick={() => {
                  if (!this.props.selectedEmoji) return;
                  this.props.hideKeyboard();
                  this.props.openGenerator();
                }}
              >
                <span>Generate Playlist</span>
              </div>
            </div>
          </div>

          <div className="emoji-list">
            {this.state.emojis.map((emoji, i) => {
              return (
                <div
                  className="emoji"
                  key={i}
                  onClick={() => {
                    this.props.selectEmoji(emoji);
                  }}
                >
                  <img src={emoji.image} alt={emoji.emoji} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selectedEmoji: state.control.selectedEmoji,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    selectEmoji: (emoji) => {
      dispatch(ControllActions.selectEmoji(emoji));
    },
    openGenerator: (emoji) => {
      dispatch(ControllActions.openGenrator());
    },
    hideKeyboard: (data) => {
      dispatch(ControllActions.toggleEmojiBoard());
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(EmojiBoard);

export default App;
