import React, { Component } from "react";
import { connect } from "react-redux";
import Snake from "react-snake";

import DesktopActions from "../../actions/DesktopActions";

import "./style.scss";

import startUp from "../../assets/audio/startup.mp3";
import closeIcon from "../../assets/images/close-icon.png";

class EmojiInput extends Component {
  render() {
    return (
      <div
        className="gameboy"
        onClick={() => {
          this.props.toggleGameboyOpen();
          if (!this.props.open) {
            this.refs.startupsound.src = startUp;
            this.refs.startupsound.play();
          }
        }}
      >
        <audio ref="startupsound" />
        <div className="loading-screen">
          <h1>FEELSFM®</h1>
        </div>
        {this.props.open && (
          <div className="gameboy-window">
            <div className="gameboy-window-inner">
              <div style={{ position: "relative" }}>
                <div className="close-btn">
                  <img src={closeIcon} alt="Close" />
                </div>
                {/* <Snake width="500" height="500" /> */}
                <Snake />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.desktop.gameboyIsOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleGameboyOpen: (data) => {
      dispatch(DesktopActions.toggleGameboyOpen());
    },
  };
};

const App = connect(mapStateToProps, mapDispatchToProps)(EmojiInput);

export default App;
