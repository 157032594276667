const initialState = {
  isOn: false,
  speed: 2,
};

export default function RecordReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_RECORD_ON":
      return {
        ...state,
        isOn: !state.isOn,
      };

    case "SWITCH_RECORD_SPEED":
      let newSpeed = state.speed + 1;
      if (newSpeed > 3) {
        newSpeed = 1;
      }
      if (action.payload) {
        newSpeed = action.payload;
      }
      return {
        ...state,
        speed: newSpeed,
      };

    default:
      break;
  }

  return state;
}
