import React from "react";

import "./style.scss";

const BotMessage = ({ image, text }) => (
  <div className="message bot">
    <div className="profile">
      <div className="profile-inner">
        <img src={image} alt="" />
      </div>
    </div>
    <div className="message-text">
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  </div>
);

export default BotMessage;
