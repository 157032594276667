import React, { Component } from "react";
import axios from "axios";

import "./style.scss";
import "../../components/Chat/style.scss";

import basketballNet from "../../assets/images/basketball-net.png";
import basketballBall from "../../assets/images/basketball-ball.png";
import basketballPop1 from "../../assets/images/basketball-pop1.png";
import basketballPop2 from "../../assets/images/basketball-pop2.png";
import basketballPop3 from "../../assets/images/basketball-pop3.png";

import ChatProfile from "../../assets/images/chat-profile.png";
import BotMessage from "../../components/Chat/botMessage";

class Basketball extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clicked: false,
      submitted: false,
      buttonDisabled: true,
      random: Math.floor(Math.random() * 3) + 1,
    };
  }

  render() {
    let popImg = basketballPop1;
    if (this.state.random === 1) {
      popImg = basketballPop2;
    } else if (this.state.random === 2) {
      popImg = basketballPop3;
    }
    return (
      <div className={this.state.clicked ? "basketball clicked" : "basketball"}>
        <img
          className="net"
          src={basketballNet}
          alt=""
          onClick={() => this.setState({ clicked: true })}
        />
        <img className="ball" src={basketballBall} alt="" />
        <img className={`pop pop${this.state.random}`} src={popImg} alt="" />

        <div
          className="basketball-popover"
          onClick={(e) => {
            if (e.target.classList.contains("basketball-popover")) {
              this.setState({
                clicked: false,
                random: Math.floor(Math.random() * 3) + 1,
              });
            }
          }}
        >
          <div className="inner chat">
            <BotMessage
              text="If you saw someone being treated unfairly because of their mental health, what would you do?"
              image={ChatProfile}
            />

            {this.state.submitted && (
              <BotMessage
                text="Thank you! Want to be in with a chance of winning a See Me grant worth £1000 to make your idea happen? <a href='
              https://seeme1.typeform.com/to/ijgAax' target='_blank'>Click here to tell us more</a>"
                image={ChatProfile}
              />
            )}

            {!this.state.submitted && (
              <div className="user-response-container">
                <div className="user-response">
                  <textarea
                    placeholder="Type something..."
                    id="basketball-response"
                    rows="5"
                    onChange={(e) => {
                      if (e.target.value !== "") {
                        this.setState({ buttonDisabled: false });
                      } else {
                        this.setState({ buttonDisabled: true });
                      }
                    }}
                  />
                </div>
                <div className="user-response-buttons">
                  <div
                    className="user-response-button"
                    onClick={() => {
                      this.setState({
                        clicked: false,
                        random: Math.floor(Math.random() * 3) + 1,
                      });
                    }}
                  >
                    <span>Cancel</span>
                  </div>
                  <div
                    className={`user-response-button primary ${
                      this.state.buttonDisabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                      let inputVal = document.getElementById(
                        "basketball-response"
                      ).value;
                      if (inputVal !== "") {
                        axios
                          .post(
                            "https://3g4shohi3h.execute-api.us-east-1.amazonaws.com/prod/penny",
                            {
                              message: inputVal,
                            }
                          )
                          .then((res) => {
                            console.log(res);
                          })
                          .catch((err) => {
                            console.log(err);
                          });

                        this.setState({
                          submitted: true,
                        });
                      }
                    }}
                  >
                    <span>Submit</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Basketball;
